<template>
	<div v-if="!this.is_loading_process || !this.isEmpty(this.errors)" class="from pb-4">
		<div v-if="!this.isEmpty(this.errors)" class="alert alert-danger rounded-1">
			<ul class="list-unstyled mb-0">
				<li v-for="(error, index) in this.errors" :key="index" v-text="error" />
			</ul>
		</div>
		<div class="form-floating mb-3">
			<input type="phone"
				   class="form-control rounded-1"
				   id="userPhone"
				   v-model="this.phone"
				   @keyup.enter="this.login()"
				   v-maska="this.use_phone_mask ? ['+7 (###) ##-##-##', '+7 (###) ###-##-##'] : ''"
				   placeholder="В формате +7..." autofocus />
			<label for="userPhone">Телефон</label>
		</div>
		<div class="form-floating mb-3">
			<input type="password"
				   class="form-control rounded-1"
				   id="userPassword"
				   v-model="this.password"
				   @keyup.enter="this.login()"
				   placeholder="Password" />
			<label for="userPassword">Пароль</label>
		</div>
		<div class="form-floating mb-3">
			<InputCheckbox inputId="remember_me"
						   inputName="remember_me"
						   inputClass="mb-0"
						   inputWrapClass="flex-column pt-1 mt-0"
						   inputLabel="- запомнить меня"
						   :inputValue="this.remember_me"
						   @setValue="(value) => this.remember_me = value" />
		</div>
		<button class="btn w-100 btn-lg py-3 text-white btn-primary"
				ref="submit_button"
				@click="this.login()">Войти</button>
		<!-- <div class="col border-dark border-top d-flex pt-3 pb-4 mt-4">
			<router-link to="/register" class="link link-dashed">Регистрация</router-link>
			<router-link to="/register" class="link link-dashed ms-auto me-0">Забыли пароль?</router-link>
		</div> -->
	</div>
	<div v-else-if="this.isEmpty(this.errors) && this.is_loading_process" class="from">
		<div class="progress mb-3" style="height: 3px;">
			<div class="progress-bar progress-bar-striped progress-bar-animated bg-success"
				 role="progressbar"
				 aria-label="Загрузка приложения"
				 :style="{width: this.loading_progress + '%'}"
				 :aria-valuenow="this.loading_progress"
				 aria-valuemin="0"
				 aria-valuemax="100">
			</div>
		</div>
		<p class="text-center text-dark-gray mb-2">{{ this.current_loading_task }}</p>
	</div>
</template>

<script>
import CommonService from "../../services/CommonService";
import api from "@/api";
import Session from "@/services/Session";
import Requisitions from "@/pages/Requisitions";
import Filters from "@/components/common/Filters";
import Clients from "@/pages/Clients";
import InputCheckbox from "@/components/inputs/InputCheckbox";

export default {
	name: "LoginForm",
	components: {
		InputCheckbox
	},
	props: {
		errorsList: {type: Array },
    use_phone_mask: { type: Boolean }
	},
	data() {
		return {
			phone: this.use_phone_mask ? '+7' : '',
			password: null,
			errors: (typeof this.errorsList !== "undefined") ? this.errorsList : [],
			remember_me: 0,
			loading_progress: 0,
			is_loading_process: false,
			current_loading_task: 'Загрузка…',
		};
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		calcProgress(promises, callback) {
			let d = 0;
			callback(0);
			for (const p of promises) {
				p.then(()=> {
					d++;
					callback((d * 100) / promises.length);
				});
			}
			return Promise.all(promises);
		},
		afterLogin(_this, direct_auth) {

			if (typeof _this == "undefined")
				_this = this;

			this.is_loading_process = true;

			var preloading_tasks = [];
			var _store = _this.$store;

			if (typeof _store !== "undefined") {

				// Список сотрудников
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getEmployees().then(data => {
						_this.current_loading_task = 'Список сотрудников…';
						_store.commit('setEmployeesList', data);
						resolve(true);
					});
				}));

				// Список партнёров
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getPartners().then(data => {
						_this.current_loading_task = 'Список партнёров…';
						_store.commit('setPartnersList', data);
						resolve(true);
					});
				}));

				// Список тегов
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getTags().then(data => {
						_this.current_loading_task = 'Список тегов…';
						_store.commit('setTagsList', data);
						resolve(true);
					});
				}));

				// Список регионов
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getRegions().then(data => {
						_this.current_loading_task = 'Список регионов…';
						_store.commit('setRegionsList', data);
						resolve(true);
					});
				}));

				// Типы коммерции
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getCommercialTypes().then(data => {
						_this.current_loading_task = 'Типы коммерции…';
						_store.commit('setCommercialList', data);
						resolve(true);
					});
				}));

				// Цели поиска
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getSearchTargets().then(data => {
						_this.current_loading_task = 'Цели поиска…';
						_store.commit('setSearchTargets', data);
						resolve(true);
					});
				}));

				// Список источников
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getSources().then(data => {
						_this.current_loading_task = 'Список источников…';
						_store.commit('setSourcesList', data);
						resolve(true);
					});
				}));

				// Список воронок
				preloading_tasks.push(new Promise((resolve, reject) => {
					Requisitions.methods.getFunnelsList().then(data => {
						_this.current_loading_task = 'Список воронок…';
						_store.commit('setReqFunnels', data);
						resolve(true);
					});
				}));

				// Список воронок (клиенты)
				preloading_tasks.push(new Promise((resolve, reject) => {
					Clients.methods.getFunnels().then(data => {
						_this.current_loading_task = 'Воронки (клиенты)…';
						_store.commit('setFunnelsList', {
							section: 'clients',
							list: data
						})
						resolve(true);
					});
				}));

				// Список воронок (заявки)
				preloading_tasks.push(new Promise((resolve, reject) => {
					Requisitions.methods.getFunnels().then(data => {
						_this.current_loading_task = 'Воронки (заявки)…';
						_store.commit('setFunnelsList', {
							section: 'requisitions',
							list: data
						})
						resolve(true);
					});
				}));

				// Список ролей пользователей (клиенты)
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getRoles('clients').then(data => {
						_this.current_loading_task = 'Роли пользователей (клиенты)…';
						_store.commit('setRolesList', {
							section: 'clients',
							list: data
						})
						resolve(true);
					});
				}));

				// Список ролей пользователей (заявки)
				preloading_tasks.push(new Promise((resolve, reject) => {
					Filters.methods.getRoles('requisitions').then(data => {
						_this.current_loading_task = 'Роли пользователей (заявки)…';
						_store.commit('setRolesList', {
							section: 'requisitions',
							list: data
						})
						resolve(true);
					});
				}));
			}

			CommonService.log('debug', 'afterLogin()::tasks', {direct_auth: direct_auth, _store: _store, preloading: preloading_tasks});

			if (direct_auth === true) {
				_this.is_loading_process = false;
				//_this.$router.push("/");
				window.location.href = "/";
			} else if (!_this.isEmpty(preloading_tasks)) {
				_this.calcProgress(preloading_tasks, (proms) => {
					_this.loading_progress = proms.toFixed(2);
				}).then(() => {
					_this.$emit('currentState', {
						proccessed: _this.is_loading_process
					});

					CommonService.debounce(() => {
						_this.is_loading_process = false;
						//_this.$router.push("/");
						window.location.href = "/";
					}, 2000);
				});
			}
		},
		login() {

			this.errors = [];
			this.$store.commit('setSessionId', null);
			Session.set('token', null);

			if (this.phone.length < 3)
				this.errors.push('Поле `Телефон` обязательно к заполнению.');

			if (this.isEmpty(this.password))
				this.errors.push('Поле `Пароль` обязательно к заполнению.');

			if (this.errors.length)
				return false;

			if (this.$refs.submit_button)
				this.$refs.submit_button.disabled = true;

			let _this = this;
			_this.is_loading_process = true;
			this.$emit('currentState', {
				proccessed: _this.is_loading_process
			});

			return api.post('/user/login', {
				phone: this.phone,
				password: this.password,
				remember_me: this.remember_me
			}, {
				headers: {
					'Access-Token': null, // Запрос без токена (авторизация)
				}
			}).then((response) => {

				CommonService.log('debug', 'login()::axios', {response});

				if (response.status == 200 && response.data.success) {
					// Читаем выданный сервером токен и запоминаем
					if (response.data.user_id && response.headers['access-token']) {

						let session_id = response.headers['access-token'];
						this.$store.commit('setSessionId', session_id);
						Session.set('token', session_id);

						_this.$store.commit('setOptions', response.data.options);

						if (response.data.options.direct_auth) {
							return _this.afterLogin(true);
						} else {
							return _this.afterLogin();
						}
					}
				} else {
					_this.errors = response.data.errors;
				}

				if (_this.$refs.submit_button)
					_this.$refs.submit_button.disabled = false;

			}).catch(function (error) {

				CommonService.log('error', 'login()::axios', error);

				_this.$emit('currentState', {
					proccessed: _this.is_loading_process
				});

				if (_this.$refs.submit_button)
					_this.$refs.submit_button.disabled = false;

			});

		},
	},
	mounted() {
		this.$emit('currentState', {
			proccessed: this.is_loading_process
		});
	}
};
</script>